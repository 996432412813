import * as React from 'react';
import { Card, CardBody } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

interface Props {}
export const LoadingIndicator: React.FC<Props> = () => (
    <Card className="shadow mb-3">
        <CardBody>
            <Icon
                className="mr-3"
                spin={true}
                size={1}
                color="black"
                path={mdiLoading}
            />
            Loading
        </CardBody>
    </Card>
);
