import * as React from 'react';
import { Container, Button, CardBody, Card } from 'reactstrap';
import axios from 'axios';

import { Drone } from '../../types/Drone';
import { connect } from 'react-redux';
import { Store } from '../../types/Store';

import { connectWithServer } from '../../actions';
import { Refresh } from '../../components/Refresh';
import {
    Logo,
    ButtonWrapper,
    LogoWrapper,
    ClosedGameCard,
    NoServerCard,
    TitleWrapper,
} from './style';
import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';
import { LoadingIndicator } from '../../components/LoadingIndicator';

export const mapStateToProps = (state: Store, ownProps: any): any => ({
    drones: state.drones,
});

export const mapDispatchToProps = (dispatch: any) => {
    return {
        connect: (drones: Drone[]) => {
            dispatch(connectWithServer(drones));
        },
    };
};

const HomeComponent: React.FC<any> = props => {
    const [canNotJoin, setCanNotJoin] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [isConnecting, setIsConnecting] = React.useState(false);

    const loadDrones = async () => {
        try {
            setCanNotJoin(false);
            setHasError(false);
            setIsConnecting(true);
            const result: { data: Drone[] } = await axios.post(
                process.env.REACT_APP_APIURL + 'entergame'
            );
            props.connect(result.data);
            setIsConnecting(false);
            props.history.replace('/droneselect');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setCanNotJoin(true);
            } else {
                setHasError(true);
            }
            setIsConnecting(false);
        }
    };

    return (
        <>
            <LogoWrapper className="shadow">
                <CardBody className="top-card">
                    <Logo src="/logo.svg"></Logo>
                    <TitleWrapper>EIGHT</TitleWrapper>
                </CardBody>
            </LogoWrapper>
            <Container>
                <Refresh />
                <ButtonWrapper>
                    {isConnecting && (
                        <div className="shadow mb-3">
                            <LoadingIndicator />
                        </div>
                    )}

                    {canNotJoin && (
                        <ClosedGameCard className="shadow mb-3">
                            <CardBody>
                                <Icon
                                    size={1}
                                    color="white"
                                    path={mdiAlertCircle}
                                    className="mr-1"
                                />{' '}
                                <b>Game is closed, wait for next round</b>
                            </CardBody>
                        </ClosedGameCard>
                    )}

                    {hasError && (
                        <NoServerCard className="shadow mb-3">
                            <CardBody>
                                <Icon
                                    size={1}
                                    color="white"
                                    path={mdiAlertCircle}
                                    className="mr-1"
                                />{' '}
                                <b>
                                    We could not connect you to the server, try
                                    again later.
                                </b>
                            </CardBody>
                        </NoServerCard>
                    )}

                    {!canNotJoin && !hasError && !isConnecting && (
                        <Card className="shadow mb-3">
                            <CardBody>
                                Make sure there is no round currently going on.
                            </CardBody>
                        </Card>
                    )}

                    <Button
                        disabled={isConnecting}
                        className="shadow"
                        color="primary"
                        size="lg"
                        block={true}
                        onClick={() => loadDrones()}>
                        JOIN GAME
                    </Button>
                </ButtonWrapper>
            </Container>
        </>
    );
};

export const Home: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeComponent);
