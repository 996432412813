import { Action } from '../actions';
import { Store } from '../types/Store';
import {
    CONNECT_WITH_SERVER,
    CHOOSE_DRONE,
    CHANGE_BOOSTERS,
    CLEAR_DATA,
    INITIALSTATE,
    RANDOMIZE_BOOSTERS,
} from '../constants/index';
import { Booster } from '../types/Booster';
import countBy from 'lodash/countBy';

export const global = (state: Store, action: Action) => {
    switch (action.type) {
        // Server connect
        case CONNECT_WITH_SERVER:
            return { ...state, drones: action.drones, connected: true };

        // Drone choose
        case CHOOSE_DRONE:
            return { ...state, selectedDrone: action.drone };

        // Boosters
        case RANDOMIZE_BOOSTERS:
            const amountOfMaxBoosters = 8;

            const currentSpeed = state.boosters.filter(x => x === Booster.Speed)
                .length;
            const currentSlow = state.boosters.filter(x => x === Booster.Slow)
                .length;

            let amountOfSpeed: number | null = null;
            let amountOfSlow: number | null = null;

            while (
                (amountOfSpeed === null && amountOfSlow === null) ||
                (amountOfSpeed === currentSpeed && amountOfSlow === currentSlow)
            ) {
                amountOfSpeed = Math.round(Math.random() * amountOfMaxBoosters);
                amountOfSlow = amountOfMaxBoosters - amountOfSpeed;
            }

            const boosters = [];
            for (let index = 0; index < (amountOfSpeed as number); index++) {
                boosters.push(Booster.Speed);
            }

            for (let index = 0; index < (amountOfSlow as number); index++) {
                boosters.push(Booster.Slow);
            }

            return {
                ...state,
                boosters: boosters,
            };
        case CHANGE_BOOSTERS:
            return { ...state, boosters: action.newBoosters };

        // Utility
        case CLEAR_DATA:
            return { ...INITIALSTATE };
        default:
            break;
    }

    return state;
};
