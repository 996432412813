import styled from 'styled-components';
import { Card } from 'reactstrap';

export const LogoWrapper = styled(Card)`
    background-color: var(--primary);
    border-radius: 0 0 32px 32px;
    border: none;
`;

export const Logo = styled.img`
    height: 225px;
    width: 225px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
`;

export const ButtonWrapper = styled.div`
    margin-top: 20%;
`;

export const ClosedGameCard = styled(Card)`
    background-color: var(--warning);
    color: white;
`;

export const NoServerCard = styled(Card)`
    background-color: var(--danger);
    color: white;
`;

export const TitleWrapper = styled.div`
    width: 100%;
    text-align: center;
    font-size: 3rem;
    color: white;
`;
