import * as React from 'react';
import { Store } from '../../types/Store';
import { connect } from 'react-redux';
import { TitleContentWrapper } from '../../components/TitleContentWrapper';
import { MainContentWrapper } from '../../components/MainContentWrapper';
import { Button, Container, CardBody, Card, Row, Col } from 'reactstrap';
import { Booster } from '../../types/Booster';
import { randomizeBoosters } from '../../actions';
import isEmpty from 'lodash/isEmpty';
import { TopContentColor } from '../../components/TopContentColor';
import { useAnimation, motion } from 'framer-motion';

export const mapStateToProps = (state: Store, ownProps: any): any => ({
    boosters: state.boosters,
    connected: state.connected,
    selectedDrone: state.selectedDrone,
});

export const mapDispatchToProps = (dispatch: any) => {
    return {
        randomizeBoostersStore: () => {
            dispatch(randomizeBoosters());
        },
    };
};

const BoosterSelectComponent: React.FC<any> = ({
    boosters,
    connected,
    history,
    randomizeBoostersStore,
    selectedDrone,
}) => {
    if (!connected) {
        history.replace('/');
    }

    const speedCount = (boosters as Booster[]).filter(x => x === Booster.Speed)
        .length;
    const slowDownCount = (boosters as Booster[]).filter(
        x => x === Booster.Slow
    ).length;

    const randomAnimationControl = useAnimation();
    randomAnimationControl.setVariants({
        used: {
            scale: [1.1, 1.0],
            transition: { duration: 0.3 },
        },
    });
    const randomizeBoosters = () => {
        randomizeBoostersStore();
    };

    const randomizeWithAnimation = () => {
        try {
            navigator.vibrate([500]);
        } catch (error) {
            // Do nothing
        }

        randomAnimationControl.start('used');
        randomizeBoosters();
    };

    const onDone = () => {
        history.replace('/ingame');
    };

    if (isEmpty(boosters)) {
        randomizeBoosters();
    }

    return (
        <>
            <TitleContentWrapper container={false}>
                <TopContentColor animationcolor={selectedDrone.color}>
                    Select powerups
                </TopContentColor>
            </TitleContentWrapper>

            <MainContentWrapper>
                <Container>
                    <p className="mt-3">Currently holding</p>

                    <motion.div animate={randomAnimationControl}>
                        <Card className="shadow mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs="2">
                                        <img
                                            style={{
                                                height: '32px',
                                                width: '32px',
                                            }}
                                            src="/Boost.png"></img>
                                    </Col>
                                    <Col xs="3">
                                        <b>{speedCount}X </b>
                                    </Col>
                                    <Col xs="7">Speed boost</Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </motion.div>
                    <motion.div animate={randomAnimationControl}>
                        <Card className="shadow mb-5">
                            <CardBody>
                                <Row>
                                    <Col xs="2">
                                        <img
                                            style={{
                                                height: '32px',
                                                width: '32px',
                                            }}
                                            src="/Slow.png"></img>
                                    </Col>
                                    <Col xs="3">
                                        <b>{slowDownCount}X </b>
                                    </Col>
                                    <Col xs="7">Slowdown trap</Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </motion.div>

                    <Button
                        block={true}
                        size="lg"
                        color="primary"
                        className="shadow"
                        onClick={() => randomizeWithAnimation()}>
                        RANDOMIZE POWERUPS
                    </Button>
                    <Button
                        block={true}
                        size="lg"
                        color="success"
                        className="shadow"
                        onClick={() => onDone()}>
                        DONE
                    </Button>
                </Container>
            </MainContentWrapper>
        </>
    );
};

export const BoosterSelect: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(BoosterSelectComponent);
