import * as React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Store } from '../../types/Store';
import { clearData } from '../../actions';
import Icon from '@mdi/react';
import { mdiRefresh } from '@mdi/js';

export const mapStateToProps = (state: Store, ownProps: any): any => ({
    connected: state.connected,
});

export const mapDispatchToProps = (dispatch: any) => {
    return {
        clear: () => {
            dispatch(clearData());
        },
    };
};

const RefreshComponent: React.FC<any> = ({ connected, clear }) => {
    return (
        <>
            {connected && (
                <div>
                    <Button
                        color="secondary"
                        block={true}
                        onClick={() => clear()}>
                        <Icon size={1} color="white" path={mdiRefresh} />{' '}
                        Disconnect from game
                    </Button>
                </div>
            )}
        </>
    );
};

export const Refresh: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(RefreshComponent);
