import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.css';
import './index.css';
import { Home } from './containers/Home';
import { Route, HashRouter as Router } from 'react-router-dom';
import { createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import { global } from './reducers';
import { INITIALSTATE } from './constants';
import { DroneSelect } from './containers/DroneSelect';
import { BoosterSelect } from './containers/BoosterSelect';
import { InGame } from './containers/InGame';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(global as any, INITIALSTATE, composeEnhancers());

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Route exact={true} path="/" component={Home} />
            <Route path="/droneselect" component={DroneSelect} />
            <Route path="/boosterselect" component={BoosterSelect} />
            <Route path="/ingame" component={InGame} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
