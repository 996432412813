import * as React from 'react';
import { Drone } from '../../types/Drone';
import { DroneCard } from './style';
import { CardBody } from 'reactstrap';

interface Props {
    drone: Drone;
}
export const InGameDrone: React.FC<Props> = ({ drone }) => (
    <DroneCard className="shadow" color={drone.color}>
        <CardBody className="top-card">Cheer on {drone.name}!</CardBody>
    </DroneCard>
);
