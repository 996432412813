import * as React from 'react';
import { connect } from 'react-redux';
import { Store } from '../../types/Store';
import { Drone } from '../../types/Drone';
import { Button, Container, Alert, Row, Col, Card, CardBody } from 'reactstrap';
import Axios from 'axios';
import { Refresh } from '../../components/Refresh';
import { changeBoosters } from '../../actions';
import { Booster } from '../../types/Booster';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { InGameDrone } from '../../components/InGameDrone';
import { TitleContentWrapper } from '../../components/TitleContentWrapper';
import { MainContentWrapper } from '../../components/MainContentWrapper';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { useAnimation, animationControls, motion } from 'framer-motion';

const mapStateToProps = (state: Store, ownProps: any): any => ({
    selectedDrone: state.selectedDrone,
    connected: state.connected,
    boosters: state.boosters,
    drones: state.drones,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        changeBoostersStore: (newBoosters: Booster[]) => {
            dispatch(changeBoosters(newBoosters));
        },
    };
};

const InGameComponent: React.FC<
    | {
          selectedDrone: Drone;
          connected: boolean;
          boosters: Booster[];
          drones: Drone[];
      }
    | any
> = ({
    selectedDrone,
    connected,
    history,
    changeBoostersStore,
    boosters,
    drones,
}) => {
    const [hasFailed, setHasFailed] = React.useState(false);
    const [gameHasStarted, setGameHasStarted] = React.useState(true);
    const [canUseBooster, setCanUseBooster] = React.useState(true);
    const [counter, setCounter] = React.useState(0);
    const [openedSlow, setOpenedSlow] = React.useState(false);

    if (!connected) {
        history.replace('/');
    }

    React.useEffect(() => {
        let interval: any = null;
        if (!canUseBooster && counter > 0) {
            interval = setInterval(() => {
                setCounter(counter => counter - 1);
            }, 1000);
        } else if (!canUseBooster && counter === 0) {
            clearInterval(interval);
            setCanUseBooster(true);
            setCounter(0);
        }
        return () => clearInterval(interval);
    }, [canUseBooster, counter]);

    const speedCount = !isEmpty(boosters)
        ? (boosters as Booster[]).filter(x => x === Booster.Speed).length
        : 0;
    const slowDownCount = !isEmpty(boosters)
        ? (boosters as Booster[]).filter(x => x === Booster.Slow).length
        : 0;

    const onSlowClick = () => {
        setOpenedSlow(true);
    };

    const onSlowBack = () => {
        setOpenedSlow(false);
    };

    const usedVariant = {
        used: {
            scale: [1.1, 1.0],
            transition: { duration: 0.3 },
        },
    };

    const speedAnimationControl = useAnimation();
    speedAnimationControl.setVariants(usedVariant);

    const slowAnimationControl = useAnimation();
    slowAnimationControl.setVariants(usedVariant);

    const onBoosterClick = async (
        boost: Booster,
        droneId = selectedDrone.id
    ) => {
        if (boost === Booster.Speed ? speedCount > 0 : slowDownCount > 0) {
            try {
                navigator.vibrate([500]);
            } catch (error) {
                // Do nothing
            }

            if (boost === Booster.Slow) {
                setOpenedSlow(false);
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        slowAnimationControl.start('used');
                    }, 100);
                    resolve();
                });
            } else {
                speedAnimationControl.start('used');
            }

            const boosterCopy = cloneDeep(boosters);
            removeBooster(boost);

            setGameHasStarted(true);
            setHasFailed(false);

            setCanUseBooster(false);
            setCounter(5);
            try {
                await Axios.post(
                    process.env.REACT_APP_APIURL +
                        (boost === Booster.Speed ? '/boost' : '/slowdown'),
                    { id: droneId }
                );
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setGameHasStarted(false);
                } else {
                    setHasFailed(true);
                }

                setCanUseBooster(true);
                setCounter(0);
                changeBoostersStore(boosterCopy);
            }
        }
    };

    const removeBooster = (booster: Booster) => {
        if (includes(boosters, booster)) {
            for (let index = 0; index < boosters.length; index++) {
                const element = boosters[index];

                if (element === booster) {
                    const newBoosters = cloneDeep(boosters);
                    newBoosters.splice(index, 1);
                    changeBoostersStore(newBoosters);
                    break;
                }
            }
        }
    };

    const cannotUseSpeed = !canUseBooster || speedCount === 0;
    const cannotUseSlowdown = !canUseBooster || slowDownCount === 0;

    const yourDroneIndex = drones.findIndex(
        (x: Drone) => x.id === selectedDrone.id
    );
    let slowdownDrones = cloneDeep(drones);
    slowdownDrones.splice(yourDroneIndex, 1);

    const renderSlowCard = () => (
        <Card className="shadow mb-2">
            <CardBody>
                <Row>
                    <Col xs="2">
                        <img
                            style={{
                                height: '28px',
                                width: '28px',
                            }}
                            src="/Slow.png"></img>
                    </Col>
                    <Col xs="3">
                        <b>{slowDownCount}X </b>
                    </Col>
                    <Col xs="6">Slow traps</Col>
                </Row>
            </CardBody>
        </Card>
    );

    return (
        <>
            {connected && (
                <div>
                    <TitleContentWrapper container={false}>
                        <InGameDrone drone={selectedDrone} />
                    </TitleContentWrapper>

                    <MainContentWrapper>
                        <Container>
                            {!openedSlow && (
                                <div className="mt-3">
                                    <Refresh></Refresh>
                                </div>
                            )}

                            {hasFailed && (
                                <Alert className="mt-3" color="danger">
                                    Oops something went wrong!
                                </Alert>
                            )}
                            {!gameHasStarted && (
                                <Alert className="mt-3" color="warning">
                                    No game currently going on. Make sure the
                                    game has started on the big screen.
                                </Alert>
                            )}

                            {!openedSlow && (
                                <div>
                                    <motion.div animate={speedAnimationControl}>
                                        <Card className="shadow mb-2 mt-3">
                                            <CardBody>
                                                <Row>
                                                    <Col xs="2">
                                                        <img
                                                            style={{
                                                                height: '28px',
                                                                width: '28px',
                                                            }}
                                                            src="/Boost.png"></img>
                                                    </Col>
                                                    <Col xs="3">
                                                        <b>{speedCount}X </b>
                                                    </Col>
                                                    <Col xs="6">Boosts</Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </motion.div>

                                    <Button
                                        block={true}
                                        disabled={cannotUseSpeed}
                                        color="success"
                                        className="shadow"
                                        onClick={() =>
                                            onBoosterClick(Booster.Speed)
                                        }>
                                        <p className="my-1">
                                            {counter > 0 && speedCount > 0
                                                ? counter
                                                : `USE BOOST`}
                                        </p>
                                    </Button>

                                    <div className="mt-3">
                                        <motion.div
                                            animate={slowAnimationControl}>
                                            {renderSlowCard()}
                                        </motion.div>
                                    </div>

                                    <Button
                                        block={true}
                                        disabled={cannotUseSlowdown}
                                        color="primary"
                                        className="shadow"
                                        onClick={() => onSlowClick()}>
                                        <p className="my-1">
                                            {counter > 0 && slowDownCount > 0
                                                ? counter
                                                : `USE SLOW`}
                                        </p>
                                    </Button>
                                </div>
                            )}

                            {openedSlow && (
                                <div className="mt-3">
                                    <Row>
                                        <Col xs="2" className="pr-0 pb-2">
                                            <div
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                                onClick={() => onSlowBack()}>
                                                <Icon
                                                    className="mt-3"
                                                    size={1}
                                                    color="black"
                                                    path={mdiArrowLeft}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="10">{renderSlowCard()}</Col>
                                    </Row>
                                    {slowdownDrones.map((drone: Drone) => (
                                        <Row className="mb-2" key={drone.id}>
                                            <Col xs="12">
                                                <Button
                                                    key={drone.id}
                                                    block={true}
                                                    disabled={cannotUseSlowdown}
                                                    style={{
                                                        backgroundColor:
                                                            drone.color,
                                                        borderColor:
                                                            drone.color,
                                                    }}
                                                    className="shadow"
                                                    onClick={() =>
                                                        onBoosterClick(
                                                            Booster.Slow,
                                                            drone.id
                                                        )
                                                    }>
                                                    <p className="my-1">
                                                        USE ON{' '}
                                                        {drone.name.toUpperCase()}
                                                    </p>
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            )}
                        </Container>
                    </MainContentWrapper>
                </div>
            )}
        </>
    );
};

export const InGame: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(InGameComponent);
