import {
    CONNECT_WITH_SERVER,
    CHOOSE_DRONE,
    CHANGE_BOOSTERS,
    SLOWDOWN_DRONE,
    CLEAR_DATA,
    RANDOMIZE_BOOSTERS,
} from '../constants';
import { Drone } from '../types/Drone';
import { Booster } from '../types/Booster';

export interface ConnectWithServer {
    type: CONNECT_WITH_SERVER;
    drones: Drone[];
}

export interface ChooseDrone {
    type: CHOOSE_DRONE;
    drone: Drone;
}

export interface RandomizeBoosters {
    type: RANDOMIZE_BOOSTERS;
}

export interface ChangeBoosters {
    type: CHANGE_BOOSTERS;
    newBoosters: Booster[];
}

export interface SlowdownDrone {
    type: SLOWDOWN_DRONE;
    newBoosters: Booster[];
}

export interface ClearData {
    type: CLEAR_DATA;
}

export type Action =
    | ConnectWithServer
    | ChooseDrone
    | ChangeBoosters
    | RandomizeBoosters
    | ClearData;

export const connectWithServer = (drones: Drone[]): ConnectWithServer => {
    return {
        type: CONNECT_WITH_SERVER,
        drones: drones,
    };
};

export const chooseDrone = (drone: Drone): ChooseDrone => {
    return {
        type: CHOOSE_DRONE,
        drone: drone,
    };
};

export const randomizeBoosters = (): RandomizeBoosters => {
    return {
        type: RANDOMIZE_BOOSTERS,
    };
};

export const changeBoosters = (newBoosters: Booster[]): ChangeBoosters => {
    return {
        type: CHANGE_BOOSTERS,
        newBoosters: newBoosters,
    };
};

export const clearData = () => {
    return {
        type: CLEAR_DATA,
    };
};
