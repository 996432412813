export const CHOOSE_DRONE = 'CHOOSE_DRONE';
export type CHOOSE_DRONE = typeof CHOOSE_DRONE;

export const RANDOMIZE_BOOSTERS = 'RANDOMIZE_BOOSTERS';
export type RANDOMIZE_BOOSTERS = typeof RANDOMIZE_BOOSTERS;

export const SLOWDOWN_DRONE = 'SLOWDOWN_DRONE';
export type SLOWDOWN_DRONE = typeof SLOWDOWN_DRONE;

export const CHANGE_BOOSTERS = 'CHANGE_BOOSTERS';
export type CHANGE_BOOSTERS = typeof CHANGE_BOOSTERS;

export const CONNECT_WITH_SERVER = 'CONNECT_WITH_SERVER';
export type CONNECT_WITH_SERVER = typeof CONNECT_WITH_SERVER;

export const CLEAR_DATA = 'CLEAR_DATA';
export type CLEAR_DATA = typeof CLEAR_DATA;

export const INITIALSTATE = {
    selectedDrone: -1,
    drones: [],
    boosters: [],
    connected: false,
};
