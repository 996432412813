import * as React from 'react';
import { Drone } from '../../types/Drone';
import { ListGroupItem, Row, Col } from 'reactstrap';
import { ColorShow, ItemHeading } from './style';

interface Props {
    drone: Drone;
    onItemClick: (droneId: number) => void;
}
export const DroneItem: React.FC<Props | any> = ({ drone, onItemClick }) => {
    return (
        <ListGroupItem onClick={() => onItemClick(drone)}>
            <Row>
                <Col xs="3">
                    <ColorShow color={drone.color}></ColorShow>
                </Col>
                <Col xs="9">
                    <ItemHeading>{drone.name}</ItemHeading>
                </Col>
            </Row>
        </ListGroupItem>
    );
};
