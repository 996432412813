import * as React from 'react';
import { Container, ListGroup, Alert } from 'reactstrap';

import { Drone } from '../../types/Drone';
import { connect } from 'react-redux';
import { Store } from '../../types/Store';

import { connectWithServer, chooseDrone } from '../../actions';
import { DroneItem } from '../../components/DroneItem';
import shuffle from 'lodash/shuffle';
import isEmpty from 'lodash/isEmpty';

import Axios from 'axios';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { TitleContentWrapper } from '../../components/TitleContentWrapper';
import { MainContentWrapper } from '../../components/MainContentWrapper';
import { TopContentColor } from '../../components/TopContentColor';

export const mapStateToProps = (state: Store, ownProps: any): any => ({
    drones: state.drones,
    connected: state.connected,
});

export const mapDispatchToProps = (dispatch: any) => {
    return {
        connect: (drones: Drone[]) => {
            dispatch(connectWithServer(drones));
        },
        selectDrone: (drone: Drone) => {
            dispatch(chooseDrone(drone));
        },
    };
};

const DroneSelectComponent: React.FC<any> = ({
    connected,
    drones,
    history,
    selectDrone,
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasFailed, setHasFailed] = React.useState(false);
    const [shuffledDrones, setShuffledDrones] = React.useState<Drone[]>([]);

    if (!connected) {
        history.replace('/');
    }

    const onItemClick = async (drone: Drone) => {
        try {
            setHasFailed(false);
            setIsLoading(true);

            await Axios.post(process.env.REACT_APP_APIURL + 'choosedrone', {
                id: drone.id,
            });

            setIsLoading(false);
            selectDrone(drone);
            history.replace('/boosterselect');
        } catch (error) {
            setHasFailed(true);
            setIsLoading(false);
        }
    };

    if (isEmpty(shuffledDrones) && connected) {
        setShuffledDrones(shuffle(drones));
    }

    return (
        <>
            <TitleContentWrapper container={false}>
                <TopContentColor shadow={false}>
                    Select a drone to support
                </TopContentColor>
            </TitleContentWrapper>

            <MainContentWrapper>
                {isLoading && (
                    <Container className="mt-3">
                        <LoadingIndicator />
                    </Container>
                )}

                {hasFailed && (
                    <Container>
                        <Alert color="danger">Oops something went wrong!</Alert>
                    </Container>
                )}
                {!isLoading && !hasFailed && (
                    <ListGroup>
                        {shuffledDrones.map((drone: Drone, index: number) => (
                            <DroneItem
                                onItemClick={onItemClick}
                                key={index}
                                drone={drone}
                            />
                        ))}
                    </ListGroup>
                )}
            </MainContentWrapper>
        </>
    );
};

export const DroneSelect: any = connect(
    mapStateToProps,
    mapDispatchToProps
)(DroneSelectComponent);
