import * as React from 'react';
import { Drone } from '../../types/Drone';
import { CardBody } from 'reactstrap';
import { DroneCard } from '../InGameDrone/style';

interface Props {
    shadow?: boolean;
    color?: string;
    animationcolor?: string;
}
export const TopContentColor: React.FC<Props> = ({
    children,
    shadow = true,
    color = 'var(--primary)',
    animationcolor = null,
}) => (
    <DroneCard
        className={shadow ? 'shadow' : ''}
        animationcolor={animationcolor}
        color={color}>
        <CardBody className="top-card">{children}</CardBody>
    </DroneCard>
);
