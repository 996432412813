import styled from 'styled-components';
import { ListGroupItemHeading } from 'reactstrap';

interface ColorShowProps {
    color: string;
}
export const ColorShow = styled.div<ColorShowProps>`
    background-color: ${({ color }) => color};
    border-radius: 100%;
    height: 48px;
    width: 48px;
`;

export const ItemHeading = styled(ListGroupItemHeading)`
    margin-top: 0.5rem;
`;
