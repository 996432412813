import styled from 'styled-components';
import { Card } from 'reactstrap';

interface DroneCardProps {
    color: string;
    animationcolor?: string;
}
export const DroneCard = styled(Card)<DroneCardProps>`
    background: ${({ color }) => color};
    color: white;
    border: none;
    padding-bottom: 16px;
    border-radius: 0 0 32px 32px;
    height: 100%;

    transition: background-color 0.2s;

    ${({ animationcolor }) =>
        animationcolor !== null
            ? `
    animation: colorchange 1.25s;
    -webkit-animation: colorchange 1.25s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @keyframes colorchange
    {
      0%   {background: ${(color: string) => color};}
      100% {background: ${animationcolor}}
    }

    @-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */
    {
      0%   {background: ${(color: string) => color};}
      100% {background: ${animationcolor}}
    }`
            : ''}
`;
