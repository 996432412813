import styled from 'styled-components';
import * as React from 'react';
import { Container } from 'reactstrap';

const ContentWrapper = styled.div`
    position: fixed;
    height: 20%;
    width: 100%;
    top: 0;
`;

interface Props {
    container?: boolean;
}
export const TitleContentWrapper: React.FC<Props> = ({
    children,
    container = true,
}) => (
    <>
        {container && (
            <Container>
                <ContentWrapper>{children}</ContentWrapper>
            </Container>
        )}
        {!container && <ContentWrapper>{children}</ContentWrapper>}
    </>
);
